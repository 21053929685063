/* Navbar.css */

.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  z-index: 999; /* Ensures the navbar stays on top of other elements */
  /* background-color: #333; */ /* Removed background color */
}

.hamburger {
  display: none;
  font-size: 2em;
  cursor: pointer;
  color: #fed766;
}


.nav-links {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Navbar link typography */

.nav-text {
  font-size: 1em;
  font-weight: bold;
}

.nav-item {
  position: relative;
  margin-left: 60px; /* Increased spacing between icons */
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.nav-icon {
  width: 34px;
  height: 34px;
  cursor: pointer;
  transition: transform 0.5s ease, opacity 0.5s ease;
  filter: invert(86%) sepia(11%) saturate(4018%) hue-rotate(308deg) brightness(102%) contrast(101%);
}

.nav-text {
  position: absolute;
  opacity: 0;
  white-space: nowrap;
  transition: transform 0.5s ease, opacity 0.5s ease;
  color: #fed766;
  left: 50%;
  transform: translateX(-50%);
}

.nav-item:hover .nav-icon {
  transform: translateX(-30px); /* Move icon to the left */
  opacity: 0;
}

.nav-item:hover .nav-text {
  opacity: 1;
}

/* Responsive styling */
@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: #333;
    padding: 10px;
    border-radius: 8px;
    width: 150px;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links .nav-item {
    margin: 10px 0;
  }

  .nav-link {
    justify-content: center; /* Center the icons and text in the column layout */
  }

  .nav-item:hover .nav-icon {
    transform: translateX(-30px); /* Move icon to the left */
    opacity: 0;
  }

  .nav-item:hover .nav-text {
    opacity: 1;
  }
}