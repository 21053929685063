/* BgProfile.css V1.1: Adjusted for responsive layout */

/* BgProfile component styling */
.bg-profile-container {
  flex: 1;
  display: flex;
  justify-content: right;
  align-items: center;
  position: relative; /* Ensures positioning for relative elements */
}

.profile-image {
  width: 800px; /* Fixed width for large screens */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
  border: 2px solid red;
}

@media (max-width: 768px) {
  .profile-image {
    width: 500px; /* Fixed width for large screens */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    border: 2px solid blue;
  }
}