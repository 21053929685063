/* LayoutGPT.css V1.1: Max width added.*/
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 2000px;
  overflow-x: hidden;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0; /* Remove any default padding */
  width: 100%; /* Ensure content also respects width constraints */
  box-sizing: border-box;
}
