/* Footer.css */
.footer {
  padding: 20px;
  background-color: #333;
  color: white;
  text-align: center;
  width: 100%;
  margin: 0 auto; /* Center the footer */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  border: 2px solid red; /* This will help visualize the footer's boundary */
}

/* Styling for the text inside the footer */
.footer p {
  margin: 0;
  font-size: 1em;
}