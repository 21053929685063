/* MainContent.css */
.main-content {
  width: 100%;
  height: 100%; /* Ensure it fills the height of its container */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  overflow-x: hidden; /* Prevent horizontal overflow */
  /* border: 2px solid green; This will help visualize the main content's boundary */
  flex: 1; /* Make the main content grow to fill the available space */
}