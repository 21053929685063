/* CardContainer.css V1.3 */

/* Main container for cards */
.card-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping for small screens */
  margin-top: 20px;
}

/* Card styling */
.card {
  flex: 1 1 calc(50% - 20px); /* Two cards side-by-side with some spacing */
  margin: 10px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  color: var(--text-color); /* Using the defined CSS variable for text color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Slightly increased border-radius for a modern look */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Soft border */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
  transform-origin: center; /* Ensures scaling from the center */
  position: relative;
  overflow: hidden;
}

.card::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(10px); /* Blurred background for a glassy effect */
  z-index: 0; /* Ensure it is below the text */
  border-radius: 10px; /* Match border radius of the card */
  pointer-events: none; /* Prevent interaction with the pseudo-element */
}

.card:hover {
  transform: scale(1.048); /* Slightly scale up on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

/* Text content within the card */
.card .text-content {
  position: relative; /* Position text content relative to the card */
  z-index: 1; /* Ensure text is on top */
}

/* Typography for headings and paragraphs */
.card h1,
.card h2,
.card h3,
.card p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.card h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.card p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column; /* Stack the cards vertically */
  }

  .card {
    flex: 1 1 100%; /* Each card takes up full width */
    margin: 10px 0; /* Add vertical spacing between cards */
  }
}
