/* styles.css V.1.2: Added responsive breakpoints and refined layout */

/* Define color palette */
:root {
  --primary-color: #012055;        /* Dark Blue */
  --secondary-color: #0182BD;      /* Bright Blue */
  --accent-color: #FFC107;         /* Vibrant Yellow */
  --neutral-background: #E0E0E0;   /* Off-White */
  --text-color: #F0F0F0;           /* Light Grey */
  --footer-color: #B39DDB;         /* Muted Purple */
}

/* Global typography */
body {
  font-family: Arial, sans-serif;  /* Sets a readable, web-safe font */
  font-size: 16px;                 /* Default font size */
  line-height: 1.6;                /* Default line height for readability */
  background-color: rgb(16, 16, 16);
  color: var(--text-color);
  margin: 0;                       /* Reset default margin */
  padding: 0;                      /* Reset default padding */
  box-sizing: border-box;          /* Ensures padding and border are included in the element's total width and height */
}

/* Main container settings */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;              /* Use the full width available */
  max-width: 1200px;        /* Prevents the content from being too wide on large screens */
  margin: 0 auto;           /* Centers the container horizontally */
  padding: 0 1rem;          /* Adds horizontal padding for small screens */
  box-sizing: border-box;   /* Ensures padding and border are included in the element's total width and height */
}

/* Responsive breakpoints */
@media (max-width: 1024px) {
  /* Tablet layout */
  .App {
    padding: 0 0.5rem; /* Adjust padding for medium screens */
  }
  body {
    font-size: 15px; /* Slightly smaller font size */
  }
}

@media (max-width: 768px) {
  /* Mobile layout */
  .App {
    max-width: 100%; /* Ensure it uses the full width of smaller devices */
    padding: 0 0.5rem;
  }
  body {
    font-size: 14px; /* Smaller font size for mobile */
  }
}

/* Additional comments for clarity */
/* This file defines the overall application structure and applies breakpoints for consistent scaling. */
