/* Content.css V1.0 */

.content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  h1 {
    font-size: 2.5em;
    color: #333;
  }