/* Introduction.css V1.2: Manual layout correction. */

/* Main container for the introduction section */
.introduction {
  display: flex;
  flex-direction: row; /* Arrange components side by side on large screens */
  align-items: flex-end; /* Align components to the bottom */
  justify-content: space-between;
  background-image: url('../../assets/images/bg_blue_texture.jpg'); /* Background texture */
  background-size: cover;
  background-position: center;
  /* min-height: 100vh; */
  padding: 20px;
  width: 100%;
  position: relative; /* Allows child elements to use absolute positioning */
}

/* Profile image container positioning */
.bg-profile-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align profile image to the bottom */
  position: relative;
}

/* IntroText positioning */
.intro-text {
  position: absolute; /* Allow overlap with the profile image */
  bottom: 10%; /* Align the text card with the bottom of the profile image */
  left: 10%; /* Offset from the left */
}

/* Responsive design for medium screens */
@media (max-width: 1024px) {
  .introduction {
    border: rgb(4, 255, 0) 2px solid;
  }

}

/* Responsive design for small screens */
@media (max-width: 768px) {
  .introduction {
    flex-direction: column; /* Stack components vertically */
    align-items: center; /* Center components */
  }

  .bg-profile-container {
    align-items: center; /* Center profile image */
  }

  .intro-text {
    bottom: 50px;
    left: auto;
    transform: none; /* Reset transform for proper centering */
    max-width: 90%; /* Ensure the text card fits on smaller screens */
    text-align: center; /* Center-align text */
  }
}
