/* Home.css */

.home-page {
  background: url('../../assets/images/bg_blue_texture.jpg') no-repeat center center fixed;
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Optional */
  min-height: 100vh;
}

.home-page .header {
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background for the header */
  color: white;
}

.home-page .content {
  padding: 20px;
  color: white;
}